import { Controller } from "@hotwired/stimulus"

const selectedProducts = new Set()

export default class extends Controller {
    static targets = ["selectedProducts", "selectedProductsContainer", "errorEmptyList"]
    static values = { currentProducts: String }

    connect() {
        const initialProductIds = JSON.parse(this.currentProductsValue);
        
        initialProductIds.forEach(productId => {
            selectedProducts.add(productId);
        });

        this.toggleSubmitButtons()
    }

    selectProduct(event) {
        event.preventDefault()
        const button = event.target
        const productId = button.id.replace(/\w+_product_/, "")

        if (selectedProducts.has(productId)) {
            button.dataset.buttonState = 'add'
            button.textContent = 'Add'
            button.classList.remove('btn-black')
            button.classList.add('btn-primary')
            const product = document.getElementById("product_" + productId).outerHTML
            this.removeProduct(productId)
        } else {
            button.dataset.buttonState = 'remove'
            button.textContent = 'Remove'
            button.classList.remove('btn-primary')
            button.classList.add('btn-black')
            const product = document.getElementById("product_" + productId).outerHTML
            this.addProduct(productId, product)
        }
    }

    addProduct(productId, product) {
        selectedProducts.add(productId)
        const productFormInput = this.createHiddenInput(productId)
        const selectedProduct = this.createSelectedProductElement(productId, product)
        this.styleSelectedProducts()
        this.selectedProductsTarget.appendChild(productFormInput)
        this.selectedProductsTarget.appendChild(selectedProduct)
        document.getElementById("available_product_" + productId).classList.add("selected")

        this.toggleSubmitButtons()
    }

    removeProduct(productId) {
        document.getElementById("product_id_" + productId).remove();
        document.getElementById("selected_product_" + productId).remove();
        
        const productButton = document.getElementById("available_product_" + productId)
        if (productButton) productButton.classList.remove("selected");

        selectedProducts.delete(productId)

        this.styleSelectedProducts()
        this.toggleSubmitButtons()
    }

    styleSelectedProducts() {
        if (selectedProducts.size == 0) {
            this.selectedProductsContainerTarget.classList.add("hidden")
        } else {
            this.selectedProductsContainerTarget.classList.remove("hidden")
            selectedProducts.forEach(productId => {
                const productButton = document.getElementById("available_product_" + productId)
                if (productButton) productButton.classList.add("selected");
            })
        }
    }

    createHiddenInput(productId) {
        const input = document.createElement("input")
        input.setAttribute("type", "hidden")
        input.setAttribute("name", "list[product_ids][]")
        input.setAttribute("value", productId)
        input.setAttribute("id", "product_id_" + productId)
        return input
    }

    createSelectedProductElement(productId, product) {
        const element = document.createElement("div")
        element.setAttribute("class", "product-name selected")
        element.setAttribute("id", "selected_product_" + productId)
        element.innerHTML = product
        return element
    }

    toggleSubmitButtons() {
        const submitButtons = document.querySelectorAll("input[type='submit']")
        if (selectedProducts.size == 0) {
            this.errorEmptyListTarget.classList.remove("hidden")
            submitButtons.forEach(button => {
                button.disabled = true
                button.classList.add("!opacity-50")
            })
        } else {
            this.errorEmptyListTarget.classList.add("hidden")
            submitButtons.forEach(button => {
                button.disabled = false
                button.classList.remove("!opacity-50")
            })
        }
    }
}