import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-request--shop-selection"
export default class extends Controller {
  static targets = [ "removeBtn" ]
  static outlets = [ "product-request--shop-selection" ]

  connect() {
  }

  async remove() {
    if ( this.#isLastShopSelection ) return
    this.element.remove()
  }

  productRequestShopSelectionOutletConnected(outlet, element) {
    this.#toggleRemoveBtnState()
  }

  productRequestShopSelectionOutletDisconnected(outlet, element) {
    this.#toggleRemoveBtnState()
  }

  get #isLastShopSelection() {
    return this.productRequestShopSelectionOutlets.length <= 1
  }

  async #toggleRemoveBtnState() {
    this.removeBtnTarget.disabled = this.#isLastShopSelection
  }
}
