import TurboModalController from "./turbomodal_controller";
import {isMobile} from "../utils/media_query";

// Connects to data-controller="drawer"
export default class extends TurboModalController  {
    static values = {
        position: String,
        mobilePosition: String,
    }

    addAppearAnimation() {
        this.modalTarget.classList.add(this.#appearAnimation)
        // this.element.classList.add("animation-fade-in")
    }

    addDisappearAnimation() {
        this.modalTarget.classList.add(this.#disappearAnimation)
        // this.element.classList.add("animation-fade-out")
    }

    get #appearAnimation() {
        if(isMobile()) {
            return this.#mobileAppearAnimation
        } else {
            switch(this.positionValue) {
                case "top":
                    return "animation-slide-in-down"
                case "bottom":
                    return "animation-slide-in-up"
                // FIXME: Reverse left and right in css, wrong direction
                case "left":
                    return "animation-slide-in-left"
                case "right":
                    return "animation-slide-in-right"
                default:
                    return "animation-slide-in-right"
            }
        }
    }

    get #mobileAppearAnimation() {
        if ( this.disableMobileAnimationValue ) return ""
        if( this.mobilePositionValue ){
            switch(this.mobilePositionValue) {
                case "top":
                    return "animation-slide-in-down"
                case "bottom":
                    return "animation-slide-in-up"
                // FIXME: Reverse left and right in css, wrong direction
                case "left":
                    return "animation-slide-in-left"
                case "right":
                    return "animation-slide-in-right"
                default:
                    return "animation-slide-in-up"
            }
        } else {
            return "animation-slide-in-up"
        }
    }

    get #disappearAnimation() {
        if(isMobile()) {
            return this.#mobileDisappearAnimation
        } else {
            switch(this.positionValue) {
                case "top":
                    return "animation-slide-out-up"
                case "bottom":
                    return "animation-slide-out-down"
                case "left":
                    return "animation-slide-out-left"
                case "right":
                    return "animation-slide-out-right"
                default:
                    return "animation-slide-out-right"
            }
        }
    }

    get #mobileDisappearAnimation() {
        if ( this.disableMobileAnimationValue ) return ""

        if( this.mobilePositionValue ){
            switch(this.mobilePositionValue) {
                case "top":
                    return "animation-slide-out-up"
                case "bottom":
                    return "animation-slide-out-down"
                case "left":
                    return "animation-slide-out-left"
                case "right":
                    return "animation-slide-out-right"
                default:
                    return "animation-slide-out-down"
            }
        } else {
            return "animation-slide-out-down"
        }
    }
}
