import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="bulk-action"
export default class extends Controller {
  static targets = [ "batchActionButton", "checkbox", "selectAll", "hint", "actionArea", "actionButton" ]

  connect() {
    this.bulkMode = false
  }

  toggleBulkActionVisibility(event) {
    this.#toggleActionAreaVisibility()
    this.#toggleSelectAllVisibility()
    this.#toggleCheckboxesVisibility()
    this.#toggleBulkMode()

    this.bulkMode ? this.showHintText() : this.hideHintText();
  }

  showHintText() {
    this.hintTarget.classList.remove('hidden')
    this.hintTarget.textContent = this.#selectedCountHint
  }

  hideHintText() {
    this.hintTarget.classList.add('hidden')
  }

  #toggleBulkMode() {
    this.bulkMode = !this.bulkMode
  }

  #toggleSelectAllVisibility() {
    this.selectAllTarget.classList.toggle('hidden')
  }

  toggleSelectAll() {
    this.selectAllTarget.checked = this.#selectedCheckbox.length === this.checkboxTargets.length
  }

  #toggleActionAreaVisibility() {
    this.actionAreaTarget.classList.toggle('hidden')
  }

  #toggleCheckboxesVisibility() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.classList.toggle('hidden')
    })
  }

  selectAll(event) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = event.currentTarget.checked
    })
    this.showHintText()
    this.toggleActionButtons()
  }

  get #selectedCountHint() {
    let selectedCount = this.#selectedCheckbox.length
    return `${selectedCount} items selected`
  }

  async requestAction(event) {
    event.preventDefault()
    // if (selectedIds.length === 0) {
    //   this.hintTarget.textContent = "Please select at least one item."
    //   return
    // }

    const formAction = event.currentTarget.dataset.formAction
    const formMethod = event.currentTarget.dataset.formMethod
    const request = new FetchRequest(formMethod, formAction, { body: JSON.stringify({ ids: this.#selectedCheckboxIds }), respondKind: "turbo_stream" })
    const response = await request.perform()
    // TODO: Check response
  }

  get #selectedCheckboxIds() {
    return this.#selectedCheckbox.map((checkbox) => checkbox.value)
  }

  get #selectedCheckbox() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  toggleActionButtons() {
    // const selectedCount = this.#selectedCheckbox.length
    //
    // if (selectedCount > 0) {
    //   this.#enableActionButtons()
    // } else {
    //   this.#disableActionButtons()
    // }
  }

  #enableActionButtons() {
    this.actionButtonTargets.forEach((button) => {
      button.disabled = false
    })
  }

  #disableActionButtons() {
      this.actionButtonTargets.forEach((button) => {
        button.disabled = true
      })
  }

  clickRow(event) {
    if (this.bulkMode && event.target.type !== 'checkbox') {
      event.preventDefault()
      const checkbox = event.currentTarget.querySelector('input[type="checkbox"]')
      checkbox.checked = !checkbox.checked
      this.showHintText()
    }
  }
}
