import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-editing-form"
export default class extends Controller {
  static targets = [ "text", "input", "editingBtn", "formBtns", "cancelBtn", "error" ]

  connect() {}

  edit() {
    this.textTarget.classList.add("hidden")
    this.inputTarget.classList.remove("hidden")
    this.editingBtnTarget.classList.remove("group-hover:inline")
    this.formBtnsTarget.classList.remove("hidden")
  }

  cancel() {
    this.textTarget.classList.remove("hidden")

    this.inputTarget.value = this.textTarget.textContent
    this.inputTarget.classList.add("hidden")

    this.editingBtnTarget.classList.add("group-hover:inline")
    this.formBtnsTarget.classList.add("hidden")
    this.errorTarget.textContent = ""
  }
}
