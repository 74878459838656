import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reset-form-after-submit"
export default class extends Controller {
  clearForm() {
    this.element.querySelectorAll("input").forEach((input) => {
      if ( input.dataset.hasOwnProperty("defaultValue") ) {
        input.value = input.dataset.defaultValue
      }
    })
  }
}
