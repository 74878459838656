import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pwa-installer"
export default class extends Controller {
  connect() {
    window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault()
        window.promptEvent = e
    })
  }

  install() {
      window.promptEvent.prompt()
  }
}
