import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select';

export default class extends Controller {
    static values = {
        selected: String,
        maxItems: Number,
        allowCreate: Boolean
    }

    connect() {
        
        let config = {
            create: this.allowCreateValue || false,  // Allow creation of new items
            persist: false, // Do not keep the created option after deselecting
            createOnBlur: true, // Create the item when the input loses focus
            maxOptions: null, // The max number of options to display in the dropdown. Set maxOptions to null for an unlimited number of options.
            maxItems: this.maxItemsValue || null, // The max number of items the user can select. A value of 1 makes the control mono-selection, null allows an unlimited number of items.
            onChange: this.#onChange.bind(this),
            createFilter: (input) => {
                return input.length > 0; // Only allow creation if the input is not empty
            }
        }

        this.tomSelect = new TomSelect(this.element, config)
    }

    #onChange(value) {
        if ( this.#isSingleOption ) this.tomSelect.blur()
    }

    get #isSingleOption() {
        return this.maxItemsValue === 1
    }
}
