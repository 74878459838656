import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="read-more"
export default class extends Controller {
  static targets = ["summary", "fullContent", "readMoreBtn"]

  connect() {

  }

  readToggle() {
    this.summaryTarget.classList.toggle("hidden")
    this.fullContentTarget.classList.toggle("hidden")
    this.readMoreBtnTextToggle()
  }

  readMoreBtnTextToggle() {
    if ( this.readMoreBtnTarget.innerText.includes("more") ) {
      this.readMoreBtnTarget.innerText = "Read less"
    }
    else {
      this.readMoreBtnTarget.innerText = "Read more"
    }
  }
}
