import { Controller } from "@hotwired/stimulus"
import { get} from "@rails/request.js";

const customParserKind = "Custom"

// Connects to data-controller="parse-rule-form"
export default class extends Controller {
  static targets = [ "customParserRules" ]
  static values = {
    fetchAvailableParserNamesUrl: String
  }

  connect() {
  }

  fetchAvailableParserNames(e) {
    get(this.fetchAvailableParserNamesUrlValue, { responseKind: "turbo-stream", query: { parser_kind: e.target.value } })
    e.target.value === customParserKind ? this.showCustomParserRules() : this.hideCustomParserRules()
  }

  hideCustomParserRules(e) {
    this.customParserRulesTarget.classList.add("hidden")
  }

  showCustomParserRules(e) {
    this.customParserRulesTarget.classList.remove("hidden")
  }
}
