import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["preview"];

  connect() {}

  selected(event) {
    const files = event.currentTarget.files;
    let imageHTML = '';

    // Logic for handling a single file
    const [file] = files;
    if (file) {
        this.previewTargets.forEach(target => target.src = URL.createObjectURL(file));
    }
  }

  submit(event) {
    const submitButton = document.querySelector('input[type="submit"]');
    submitButton.click();
  }
}
