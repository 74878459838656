import { Application } from "@hotwired/stimulus"

const application = Application.start()

import LocalTime from "local-time"
LocalTime.start()

// Register stimulus confetti controller, Don't register in index.js, otherwise it will be removed after stimulus controller generated
import { Confetti } from "stimulus-confetti"
application.register('confetti', Confetti)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
