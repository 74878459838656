import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit-form"
export default class extends Controller {
  static values = {
    flag: String,
    turboFrame: String
  }

  connect() {
    if ((this.flagValue?.length || 0) > 0) {
      if ( this.turboFrameValue ) {
        this.element.dataset.turboFrame = this.turboFrameValue
      }
      this.element.requestSubmit()
      setTimeout(() => {
        delete this.element.dataset.turboFrame
        this.element.dataset.controller = this.element.dataset.controller.replace("auto-submit-form", "")
      })
    }
  }
}
