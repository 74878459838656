import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "header", "title", "description", "form", "urlInput", "loader", "error" ]

  static values = {
    url: String
  }

  connect() {
    if ((this.urlValue?.length || 0) > 0) {
      this.formTarget.requestSubmit()
    }

    document.addEventListener("turbo:before-cache", () => {
      this.formTarget.classList.remove('hidden')
    })
  }

  clearSearch(event) {
    document.getElementById('clear_search')?.click();
  }

  // Validate URL before submit
  validateURL(e) {
    if (this.isValidURL(this.#url)) {
      this.errorTarget.classList.add('hidden');
    } else {
      e.preventDefault()
      this.errorTarget.textContent = "Please enter a valid URL of a product page";
      this.errorTarget.classList.remove('hidden');
    }
  }

  beforeSubmit(e) {
    if (!this.isValidURL(this.#url)) {
      e.preventDefault()
      this.errorTarget.textContent = "Please enter a valid URL of a product page";
      this.errorTarget.classList.remove('hidden');
      return
    }

    this.#showLoader()
  }

  get #url() {
    return this.urlInputTarget.querySelector('input').value;
  }

  search(event) {
    const url = this.urlInputTarget.querySelector('input').value;
    if (this.isValidURL(url)) {
      this.errorTarget.classList.add('hidden');
      this.getMetadata(url);
    } else {
      this.errorTarget.textContent = "Please enter a valid URL of a product page";
      this.errorTarget.classList.remove('hidden');
    }
  }

  isValidURL(str) {
    try {
      const newUrl = new URL(str);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  #showLoader() {
    this.headerTargets.forEach(headerTarget => headerTarget.classList.add('hidden'));
    this.titleTarget.classList.add('hidden');
    this.formTarget.classList.add('hidden')
    this.descriptionTarget.classList.add('hidden')
    this.loaderTarget.classList.remove('hidden');
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden');
    this.headerTargets.forEach(headerTarget => headerTarget.classList.remove('hidden'));
    this.formTarget.classList.remove('hidden')
    this.descriptionTarget.classList.remove('hidden')
  }
}
