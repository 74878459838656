import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "title", "titleContainer", "input"]

  connect() {
  }

  edit(event) {
    this.formTarget.classList.remove("hidden");
    this.titleContainerTarget.classList.add("hidden");
    this.inputTarget.focus();
  }

  save(event) {
  }
}
