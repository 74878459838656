import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown-button"
export default class extends Controller {
  static targets = [ "button", "counter", "hint", "form" ]
  static values = {
    secondsLeftToResend: { type: Number, default: 0 },
    hiddenClass: { type: String, default: "hidden" },
    duration: { type: Number, default: 60 },
    disabledClass: { type: String, default: "disabled" }
  }

  connect() {
    if (this.secondsLeftToResendValue > 0) this.#startCounter()
  }

  start(event) {
    if (this.running) return
    this.running = true

    this.#startCounter()
    event.preventDefault()
    this.formTarget.requestSubmit()
  }

  #startCounter() {
    this.counter = this.#secondsLeftToResend
    this.buttonTarget.disabled = true

    this.intervalTimer = setInterval(() => {
      if (this.counter > 0) {
        this.counter--
        this.#showHint()
        this.counterTarget.textContent = this.counter
      }
    }, 1000)

    this.timeoutTimer = setTimeout(() => {
      this.#resetCounter()
    }, this.#secondsLeftToResend * 1000)
  }

  #resetCounter() {
    this.running = false
    this.#hideHint()
    this.secondsLeftToResendValue = this.durationValue
    clearInterval(this.intervalTimer)
    this.buttonTarget.disabled = false
  }

  get #secondsLeftToResend() {
    if ( this.secondsLeftToResendValue > 0 ) {
      return this.secondsLeftToResendValue
    } else {
      return this.durationValue
    }
  }

  #showHint() {
    this.hintTarget.classList.remove(this.hiddenClassValue)
  }

  #hideHint() {
    this.hintTarget.classList.add(this.hiddenClassValue)
  }

  disconnect() {
    clearTimeout(this.timeoutTimer)
    clearInterval(this.intervalTimer)
  }
}
