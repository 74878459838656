import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs-activation"
export default class extends Controller {
  static classes = [ "active" ]
  static targets = [ "tab" ]

  connect() {

  }

  active(e) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(this.activeClass)
    })
    e.currentTarget.classList.add(this.activeClass)
  }
}
