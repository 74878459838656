import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"]
  
    search() {
      debounce(() => {
        this.formTarget.requestSubmit();
      }, 250)() // Debounce the input to prevent excessive requests
    }
}