import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-search"
export default class extends Controller {
  static targets = ["clearSearch", 'clearText']
  connect() {

  }

  showClearSearchButton() {
    this.clearTextTarget.classList.add("hidden")
    this.clearSearchTarget.classList.remove("hidden")
  }
}
