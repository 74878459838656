import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pop-history"
export default class extends Controller {

  connect() {
    this.referrer = document.referrer
  }

  act(e) {
    history.replaceState({}, document.title, this.referrer)
    Turbo.navigator.history.replace(this.referrer)
  }
}
