
const debounce = (func, wait) => {
    let timeoutId = null

    return (...args) => {
        window.clearTimeout(timeoutId)

        timeoutId = window.setTimeout(() => {
            func.apply(null, args);
        }, wait)
    }
}

export default debounce
