import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["source", "success"]

    connect() {
    }

    copy(event) {
        event.preventDefault();
        
        const text = this.sourceTarget.innerHTML || this.sourceTarget.value
        
        navigator.clipboard.writeText(text).then(() => this.copied())
    }

    copied() {    
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    
        this.successTarget.classList.remove("hidden");
        
        this.timeout = setTimeout(() => {
            this.successTarget.classList.add("hidden", "delay-300");
        }, 1500);
    }
}
