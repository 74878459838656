import { Controller } from "@hotwired/stimulus"
import { useVisibility } from "stimulus-use"; 

export default class extends Controller {
  static targets = [ "back", "feed" ]

  connect() {
    // this.toggleBackButtonVisibility();
    useVisibility(this);
  }

  disconnect() {
    this.feedTarget.classList.add("hidden");
  }

  visible() {
    this.toggleBackButtonVisibility();
  }

  goBack() {
    window.history.back();
  }

  toggleBackButtonVisibility() {
    const lastVisited = sessionStorage.getItem("lastVisited");
    const cameFromWithin = lastVisited && lastVisited.startsWith(window.location.origin);

    if (cameFromWithin) {
      this.backTarget.classList.remove("hidden");
    } else {
      this.backTarget.classList.add("hidden");
      
      this.feedTarget.classList.remove("hidden");
      this.feedTarget.classList.add("shrink-0", "block");
    }
  }
}