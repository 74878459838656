import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-selector"
export default class extends Controller {
  static targets = ["addButton", "changeButton", "description", "backdrop", "border"]
  static values = {
    selected: Boolean
  }

  connect() {
    if (this.selectedValue) {
      this.selected()
    }
  }

  selected() {
    this.descriptionTarget.classList.add("hidden")
    this.addButtonTarget.classList.add("hidden")
    this.backdropTarget.classList.remove("hidden")
    this.changeButtonTarget.classList.remove("hidden")
    this.borderTarget.classList.add("border-none")
  }
}
