import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

// Connects to data-controller="product-request--platform-selection"
export default class extends Controller {
  connect() {
    console.log("platform-selection connected")
  }

  async change({ target }) {
    console.log("changed")

    const response = await get(`/admin/products/requests/shops?platform_id=${target.value}`, {
      responseKind: "turbo-stream"
    })

    if( response.ok ) {
      // handle success
    } else {
      console.error(response)
    }
  }
}
