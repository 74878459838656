import { Controller } from "@hotwired/stimulus"
import { defaultCurrency } from "../config"

// Connects to data-controller="order-form"
export default class extends Controller {
  static targets = [ "lineItem", "subTotalPrice", "totalPrice" ]
  static values = {
    shippingFee: Number
  }

  connect() {
  }

  updateTotal() {
    // Prevent update total price when total price is negative.
    if( parseFloat(this.#calculateSubTotal()) >= 0 ) {
      this.subTotalPriceTarget.innerText = this.#calculateSubTotal().replace(".", defaultCurrency.decimalMark)
      this.totalPriceTarget.innerText = this.#calculateTotal().replace(".", defaultCurrency.decimalMark)
    }
  }

  #calculateTotal() {
    let total = this.shippingFeeValue
    this.lineItemTargets.forEach((lineItem) => {
      total += parseFloat(lineItem.dataset.price) * parseFloat(lineItem.dataset.quantity)
    })
    return total.toFixed(2)
  }

  #calculateSubTotal() {
    let total = 0
    this.lineItemTargets.forEach((lineItem) => {
      total += parseFloat(lineItem.dataset.price) * parseFloat(lineItem.dataset.quantity)
    })
    return total.toFixed(2)
  }

  resetOrderCache() {
    this.#lineItemControllers.forEach(lineItemController => lineItemController.resetQuantity())
  }

  get #lineItemControllers() {
    return this.lineItemTargets.map((lineItemTarget) => Stimulus.getControllerForElementAndIdentifier(lineItemTarget, "order-form-line-item"))
  }
}
