import { Controller } from "@hotwired/stimulus"
import debounce from "../utils/debounce";


// Connects to data-controller="instant-submit-form"
export default class extends Controller {
  connect() {
    let submitForm = debounce((e) => {
      this.element.requestSubmit();
    }, 300)

    this.element.addEventListener("change", submitForm)
  }
}
