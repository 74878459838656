import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"

// Connects to data-controller="uploader"
export default class extends Controller {
  static targets = [ "ui", "field", "existsImageDestroyed" ]
  static values = {
    id: { type: String, default: "uppy" },
    maxFileSize: { type: Number, default: 26214400 },
    allowedFileTypes: { type: Array, default: ['image/*'] },
    note: { type: String, default: "Upload images for the product" },
    height: { type: String, default: "200px" }
  }

  connect() {
    this.initUppy()
  }

  initUppy() {
    this.uppy = new Uppy({
      id: this.idValue,
      autoProceed: true,
      restrictions: {
        maxFileSize: this.maxFileSizeValue,
        allowedFileTypes: this.allowedFileTypesValue
      },
    }).use(Dashboard, {
      inline: true,
      target: this.uiTarget,
      height: this.heightValue,
      note: this.noteValue,
      proudlyDisplayPoweredByUppy: false,
    }).use(AwsS3, {
      companionUrl: "/",
    }).on("upload-success", this.#uploadSuccess.bind(this))
  }

  #uploadSuccess(file, response) {
    const uploadedFileData = {
      id: file.meta['key'].match(/^.*cache\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type
      }
    }

    // Upload a new product image will removed all the existing images of the product
    this.existsImageDestroyedTargets.forEach((element) => element.value = "1" )

    this.fieldTarget.value = JSON.stringify(uploadedFileData)
  }
}
