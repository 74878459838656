import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "input" ]

  connect() { 
    const input = this.inputTarget
    if (input.value) this.moveCursorToEnd(input)
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
        this.formTarget.requestSubmit()
        }, 500)
  }

  moveCursorToEnd(input) {
    input.selectionStart = input.selectionEnd = input.value.length
  }
}