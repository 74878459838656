import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drag-to-scroll"
export default class extends Controller {
  connect() {
    this.pos = { left: 0, top: 0, x: 0, y: 0 }
    this.boundMouseDownHandler = this.mouseDownHandler.bind(this)
    this.element.addEventListener("mousedown", this.boundMouseDownHandler)
  }

  disconnect() {
    document.removeEventListener("mousedown", this.boundMouseDownHandler)
  }

  mouseDownHandler(e) {
    this.element.style.cursor = 'grabbing';
    this.element.style.userSelect = 'none';

    this.pos = {
      // The current scroll
      left: this.element.scrollLeft,
      top: this.element.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    this.boundMouseMoveHandler = this.mouseMoveHandler.bind(this)
    this.boundMouseUpHandler = this.mouseUpHandler.bind(this)
    document.addEventListener('mousemove', this.boundMouseMoveHandler)
    document.addEventListener('mouseup', this.boundMouseUpHandler)
    this.element.addEventListener("dragend", this.boundMouseUpHandler)
  }

  mouseMoveHandler (e) {
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    // Scroll the element
    this.element.scrollTop = this.pos.top - dy;
    this.element.scrollLeft = this.pos.left - dx;
  };

  mouseUpHandler(e) {
    e.stopImmediatePropagation();
    document.removeEventListener('mousemove', this.boundMouseMoveHandler);
    document.removeEventListener('mouseup', this.boundMouseUpHandler);

    this.element.style.cursor = 'grab';
    this.element.style.removeProperty('user-select');
  }
}
