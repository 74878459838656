import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vibrate"
export default class extends Controller {
  connect() {
  }

  invoke() {
    if ( navigator.vibrate ) {
      navigator.vibrate(30);
    }
  }
}
