import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hidden-field"
export default class extends Controller {
  static targets = ["form"]

  change(event) {
    const params = event.params
    const field = this.formTarget.querySelector(`[name="${params.name}"]`)
    field.value = params.value
  }
}
