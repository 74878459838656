import {Controller} from "@hotwired/stimulus"
import { post } from "@rails/request.js";

// Connects to data-controller="parse-rule-check"
export default class extends Controller {
    static targets = ["parseRuleForm", "sampleUrl", "checkButton"]
    static values = {
        parseRuleCheckUrl: String
    }

    connect() {}

    async check() {
        this.#checkButtonLoading()
        const response = await post(
            this.parseRuleCheckUrlValue, {
                responseKind: "turbo-stream",
                body: this.#formData
            }
        )
        this.#checkButtonLoaded()
    }

    #checkButtonLoading() {
        this.checkButtonTarget.setAttribute("disabled", true)
        this.checkButtonTarget.querySelector("span").innerText = "Checking..."
    }

    #checkButtonLoaded() {
        this.checkButtonTarget.removeAttribute("disabled")
        this.checkButtonTarget.querySelector("span").innerText = "Check"
    }

    get #formData() {
        const formData = new FormData(this.parseRuleFormTarget)
        formData.delete("_method")
        formData.append("sample_url", this.sampleUrlTarget.value)
        return formData
    }
}
