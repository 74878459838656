import {Controller} from "@hotwired/stimulus"
import MiniMasonry from "minimasonry"
import { isMobile } from "../utils/media_query";

// Connects to data-controller="masonry"
export default class extends Controller {
    static targets = ["item"]
    static values = { itemWidth: Number }

    connect() {
        let baseWidth, gutterX, gutterY
        if( isMobile() ) {
            baseWidth = 150
            gutterX = 12
            gutterY = 16
        } else {
            baseWidth = this.itemWidthValue || 150
            gutterX = 18
            gutterY = 32
        }

        this.masonry = new MiniMasonry({
            container: this.element,
            wedge: true,
            baseWidth,
            gutterX,
            gutterY,
            minify: true
        })
        this.itemTargets.filter(item => item.classList.contains("opacity-0")).forEach(item => item.classList.remove("opacity-0"))
    }

    disconnect() {
        this.masonry.destroy()
    }

    relayout() {
        this.masonry.layout()
        this.itemTargets.forEach(item => item.classList.remove("opacity-0"))
    }
}
