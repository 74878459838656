import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-bubbles"
export default class extends Controller {
  static targets = ["radioBubble"]

  changeRadioBubble(event) {
    this.radioBubbleTargets.forEach(radioBubble => {
      if (radioBubble === event.currentTarget) {
        radioBubble.classList.add("active")
      } else {
        radioBubble.classList.remove("active")
      }
    })
  }
}
