if (navigator.serviceWorker) {
    navigator.serviceWorker.register("/service-worker.js", { scope: "/" })
        .then(() => navigator.serviceWorker.ready)
        .then((registration) => {
            if ("SyncManager" in window) {
                registration.sync.register("sync-forms");
            } else {
                console.log("This browser does not support background sync.")
            }
            // Notification.requestPermission().then((result) => {
            //     console.log(result);
            // });
            //
            // if (window.Notification && Notification.permission === 'granted') {
            //     const options = {
            //         body: 'Hello ian',
            //         icon: document.querySelector("meta[name=icon-url]").content,
            //     };
            //     registration.showNotification('Welcome to FRL!', options);
            // }
        }).then(() => console.log("[Companion]", "Service worker registered!"))
}
