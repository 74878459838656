import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout"
export default class extends Controller {
  static targets = [ "mountPoint" ]

  static values = {
    clientSecret: String
  }

  connect() {
    this.#mountCheckout()
  }

  async #mountCheckout() {
    const stripe = Stripe(this.#publishableKey)
    this.checkout = await stripe.initEmbeddedCheckout({
      fetchClientSecret: async () => this.clientSecretValue,
    })

    this.checkout.mount(this.mountPointTarget)
  }

  get #publishableKey() {
    return document.querySelector("meta[name='stripe-publishable-key']").content
  }

  disconnect() {
    this.checkout.destroy()
  }
}
