import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
      this.setRoundedClasses();
    }

    setRoundedClasses() {
      // Get all tab elements within this controller's scope
      const tabs = this.element.querySelectorAll('.tab');

      // Clear existing rounded classes
      tabs.forEach(tab => {
        tab.classList.remove('rounded-br-3xl', 'rounded-bl-3xl');
      });

      // Find the index of the active tab
      const activeIndex = Array.from(tabs).findIndex(tab => tab.classList.contains('active'));

      // Apply rounded classes to previous and next tabs of the active tab
      if (activeIndex > 0) {
        tabs[activeIndex - 1].classList.add('rounded-br-3xl');
        tabs[activeIndex - 1].firstElementChild.classList.add('md:rounded-none');
      }
      if (activeIndex < tabs.length - 1) {
        tabs[activeIndex + 1].classList.add('rounded-bl-3xl');
        tabs[activeIndex + 1].firstElementChild.classList.add('md:rounded-none');
      }
    }

    // If the tabs dynamically change, you may need to call this method
    refreshRoundedClasses() {
      this.setRoundedClasses();
    }
}
