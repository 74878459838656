import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="admin--shop-product--platform-selection"
export default class extends Controller {
  static values = {
    url: String
  }
  connect() {
  }

  async change({ target }) {
    const platformId = target.value
    this.fetchShops(platformId)
  }

  async fetchShops(platformId) {
    const url = `${this.urlValue}?platform_id=${platformId}`
    const response = await get(url, { responseKind: "turbo-stream" })
    if (response.ok) {

    } else {
      console.error(response)
    }
  }
}
