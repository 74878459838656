import { Controller } from "@hotwired/stimulus"
import {useClickOutside} from "stimulus-use";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = [ "dropdownButton", "dropdownMenu" ]
  static values = {
    direction: String
  }

  connect() {
    useClickOutside(this )
    this.animating = false
  }

  toggle() {
    if( this.dropdownMenuTarget.classList.contains("hidden")) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    if ( this.animating ) return
    this.#setAnimating()

    this.dropdownMenuTarget.classList.remove("hidden")
    this.dropdownMenuTarget.classList.add(this.#showAnimationClass)
    this.dropdownMenuTarget.classList.remove(this.#hideAnimationClass)
  }

  hide() {
    if( this.animating ) return
    this.#setAnimating()

    this.dropdownMenuTarget.classList.remove(this.#showAnimationClass)
    this.dropdownMenuTarget.classList.add(this.#hideAnimationClass)
    setTimeout(() => {
      this.dropdownMenuTarget.classList.add("hidden")
    }, 250)
  }

  #setAnimating() {
    this.animating = true
    setTimeout(() => {
      this.animating = false
    }, 300)
  }

  get #showAnimationClass() {
    if (this.#direction === "down") {
      return "animation-fade-in-down"
    } else {
      return "animation-fade-in-up"
    }
  }

  get #hideAnimationClass() {
    if (this.#direction === "down") {
      return "animation-fade-out-up"
    } else {
      return "animation-fade-out-down"
    }
  }

  get #direction() {
    return this.directionValue || "down"
  }

  clickOutside(event) {
    this.hide()
  }
}
