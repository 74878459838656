import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-form-line-item"
export default class extends Controller {
  static targets = [ "quantityField" ]
  static values = {
    shopProductId: String,
    variantId: String
  }

  connect() {
    this.#restoreQuantity()
  }

  updateQuantity() {
    this.element.dataset.quantity = this.#quantity
    this.#storeQuantity()
    this.#triggerQuantityChangedEvent()
  }

  incrementQuantity() {
    this.quantityFieldTarget.value = parseInt(this.#quantity) + 1
    this.element.dataset.quantity = this.#quantity
    this.#storeQuantity()
    this.#triggerQuantityChangedEvent()
  }

  decrementQuantity() {
    if (parseInt(this.#quantity) > 1) {
      this.quantityFieldTarget.value = parseInt(this.#quantity) - 1
      this.element.dataset.quantity = this.#quantity
      this.#storeQuantity()
      this.#triggerQuantityChangedEvent()
    }
  }

  #triggerQuantityChangedEvent() {
    this.element.dispatchEvent(new CustomEvent("quantity-changed"))
  }

  #storeQuantity() {
    sessionStorage.setItem(this.#quantityCachedKey, this.#quantity)
  }

  #restoreQuantity() {
    const cachedQuantity = sessionStorage.getItem(this.#quantityCachedKey)
    if (cachedQuantity) {
      this.quantityFieldTarget.value = cachedQuantity
      this.element.dataset.quantity = cachedQuantity
      this.#triggerQuantityChangedEvent()
    }
  }

  resetQuantity() {
    sessionStorage.removeItem(this.#quantityCachedKey)
  }

  get #quantityCachedKey() {
    return `shopProductId:${this.shopProductIdValue}:variantId:${this.variantIdValue}`
  }

  get #quantity() {
    return this.quantityFieldTarget.value
  }
}
