import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="explore--bubbles-nav-position-restoration"

let memoKey = "explore-bubbles-scroll-position"

export default class extends Controller {
  static values = {
    reset: Boolean
  }

  connect() {
    if (this.resetValue) this.#reset()
    this.#restore()
  }

  get #canRestore() {
    return !!this.#storedPosition
  }

  #restore() {
    if (this.#canRestore) this.element.scrollTo({ left: this.#storedPosition })
  }

  store() {
    localStorage.setItem(memoKey, this.element.scrollLeft)
  }

  #reset() {
    localStorage.setItem(memoKey, 0)
  }

  get #storedPosition() {
    return localStorage.getItem(memoKey)
  }
}
