import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js";

// Connects to data-controller="ahoy-tracker"
export default class extends Controller {
  static values = {
    event: String,
    props: Object
  }

  track() {
    ahoy.track(this.eventValue, this.propsValue)
  }
}
